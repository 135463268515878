<template>
  <div
    class="masonry-item_type_note"
  >
    <div
      class="masonry-content"
      @click="$emit('tryReadItem', { item, fbId: itemId })"
    >
      <div
        class="preview-container"
        :class="{
          clamp: cutTextContent
        }"
        v-html="item.content.text" 
      ></div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    dark: {
      type: Boolean
    },
    cutTextContent: {
      default: true,
      type: Boolean
    },
    itemId: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  name: 'ItemTypeNote',
  mounted() {
    this.$emit('needUpdateView')
  }
}
</script>
<style lang="scss">
.item-dialog__content .masonry-item_type_note {
  padding-bottom: 20vh;
  padding-right: 20px;
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 27;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
</style>